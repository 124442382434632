import Logo from "../assets/logo.svg";
const Navbar = ({ logo }) => {
	return (
		<div className="navbar">
			<div className="navbar-logo">
				<img
					src={logo || Logo}
					alt="Logo"
					//  width={"136px"} height={"70px"}
				/>
			</div>
		</div>
	);
};

export default Navbar;
