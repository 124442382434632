import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import Navbar from "./components/Navbar";
import FormContainer from "./components/FormContainer";
import Loading from "./components/Loading";
import Banner from "./components/Banner";

const defaultVars = {
	name: "Innential",
	mainColor: "#8745EF",
	logo: "https://via.placeholder.com/150",
	slug: "Innential",
	mainLanguage: "English",
	devices: [
		{
			deviceName: "Inverter",
			exampleQuestions: [
				"What types of courses pliz offers on machinery safety and automation?",
				"What are the different types of courses pliz offers on machinery safety and automation?",
			],
		},
		{
			deviceName: "Battery",
			exampleQuestions: ["What types of batteries are safe to use?"],
		},
		{ deviceName: "Solar Panel", exampleQuestions: [] },
	],
	deviceTypes: [
		{
			type: "Heat Pump",
			manufacturers: [
				{
					manufacturer: "SMA",
					companyName: "SMA-1",
					devices: [
						{
							deviceName: "Model30",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model4",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model5",
							exampleQuestions: [],
						},
						{
							deviceName: "Model12",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model11",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model8",
							exampleQuestions: [],
						},
						{
							deviceName: "Model9",
							exampleQuestions: [],
						},
						{
							deviceName: "Model10",
							exampleQuestions: [],
						},
					],
				},
				{
					manufacturer: "Zolar",
					companyName: "Zolar-1",
					devices: [
						{
							deviceName: "Model1",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model3",
							exampleQuestions: [
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
					],
				},
			],
		},
		{
			type: "Solar Panel",
			manufacturers: [
				{
					manufacturer: "SMA",
					companyName: "SMA-12",
					devices: [
						{
							deviceName: "Model4",
							exampleQuestions: [],
						},
						{
							deviceName: "Model5",
							exampleQuestions: [],
						},
					],
				},
			],
		},
	],
	type: "Manufacturer",
	// type: "Distributor",
	aiTrained: false,
};

function App() {
  const [variables, setVariables] = useState(defaultVars);
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackIsAdded, setFeedbackIsAdded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const instanceId = process.env.REACT_APP_INSTANCE_ID;
    const environment = process.env.REACT_APP_ENV;

    // Check if instanceId is not available
    if (!instanceId) {
      setVariables(defaultVars);
      setIsLoading(false);
      return;
    }

    let api;
    switch (environment) {
      case "development":
        api = "http://localhost:9000/instance";
        break;
      case "staging":
        api = "https://admin-api.service-assistant-staging.com/instance";
        break;
      case "production":
        api = "https://admin-api.service-assistant.ai/instance";
        break;
      default:
        console.warn(`Unsupported environment: ${environment}`);
        setIsLoading(false); // Set loading state to false if environment is unsupported
        return; // Exit useEffect early
    }

    // Ensure api is defined before proceeding
    if (!api) {
      console.error("API URL could not be determined.");
      setIsLoading(false);
      return;
    }

    const sendData = {
      instanceId: instanceId,
    };

    axios
      .get(api, {
        params: sendData,
      })
      .then((response) => {
        setVariables(response.data);
        const pageLinks = document.getElementsByTagName("link");
        const pageLink = Array.from(pageLinks).find((link) => {
          return link.rel === "icon";
        });
        if (pageLink && response?.data?.favIcon) {
          pageLink.href = response.data.favIcon;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
		<div className="app">
			<Banner feedbackIsAdded={feedbackIsAdded} />
			<Navbar logo={variables.logo} />
			<div className="main-container">
				<FormContainer variables={variables} setFeedbackIsAdded={setFeedbackIsAdded} />
			</div>
		</div>
	);
}

export default App;
