/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";

const AutoSelectDistributors = ({
	items,
	userInput,
	setUserInput,
	setExampleQuestions,
}) => {
	const [manufacturers, setManufacturers] = useState([]);
	const [devices, setDevices] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedManufacturer, setSelectedManufacturer] = useState(null);
	const [selectedDevice, setSelectedDevice] = useState(null);

	const types =
		items
			.map((item) => item.type)
			.map((item) => ({ value: item, label: item })) || [];

	useEffect(() => {
		if (selectedType) {
			const manufacturers = items
				.find((item) => item.type === selectedType.value)
				.manufacturers.map((item) => item.manufacturer)
				.map((item) => ({ value: item, label: item }));
			setManufacturers(manufacturers);
			setSelectedManufacturer(null); // Reset selected manufacturer when type changes
			setDevices([]); // Reset devices when type changes
			setSelectedDevice(null); // Reset selected device when type changes
			setUserInput({ ...userInput, device: "" });
		} else {
			setManufacturers([]);
			setSelectedManufacturer(null);
			setDevices([]);
			setSelectedDevice(null);
			setExampleQuestions([]);
		}
	}, [items, selectedType]);

	useEffect(() => {
		if (selectedType && selectedManufacturer) {
			const manufacturer = items
				.find((item) => item.type === selectedType.value)
				.manufacturers.find(
					(item) => item.manufacturer === selectedManufacturer.value
				);
			if (manufacturer) {
				const devices = manufacturer.devices.map((item) => ({
					value: item.deviceName,
					label: item.deviceName,
				}));
				setDevices(devices);
				setSelectedDevice(null); // Reset selected device when manufacturer changes
				setUserInput({ ...userInput, device: "", company: manufacturer.companyName});
				setExampleQuestions([]);
			} else {
				setDevices([]);
				setSelectedDevice(null);
				setUserInput({ ...userInput, device: "" });
				setExampleQuestions([]);
			}
		} else {
			setDevices([]);
			setSelectedDevice(null);
			setExampleQuestions([]);
		}
	}, [items, selectedManufacturer, selectedType]);

	return (
		<>
			<label htmlFor="type">Device Type</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={types}
				placeholder="Enter your device type"
				value={selectedType}
				onChange={(value) => {
					setSelectedType(value);
					setUserInput({ ...userInput, device: "" });
				}}
			/>
			<label htmlFor="manufacturer">Manufacturer</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={manufacturers}
				placeholder="Enter your device manufacturer"
				value={selectedManufacturer}
				onChange={(value) => {
					setSelectedManufacturer(value);
					setUserInput({ ...userInput, device: "" });
				}}
			/>
			<label htmlFor="device">Device Model</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={devices}
				placeholder="Enter your device model"
				value={selectedDevice}
				onChange={(value) => {
					setSelectedDevice(value);
					setUserInput({ ...userInput, device: value?.value || "" });
					setExampleQuestions(
						items
							.find((item) => item.type === selectedType.value)
							.manufacturers.find(
								(item) => item.manufacturer === selectedManufacturer.value
							)
							.devices.find((item) => item.deviceName === value.value)
							.exampleQuestions
					);
				}}
			/>
		</>
	);
};

export default AutoSelectDistributors;
